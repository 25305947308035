<template v-if="notes && notes.length > 0">
  <div>
    <v-row dense v-for="note in getRecords" :key="note.id">
      <v-col>
        <v-card class="mb-3" :flat="flat">
          <v-toolbar flat dense tile>
            <v-toolbar-title>
              {{ note.title }}
            </v-toolbar-title>
            <v-spacer/>
            <template v-if="note.is_request">
              <v-tooltip v-if="!note.request_fulfilled" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="mr-3" small color="red">mdi-checkbox-blank-circle</v-icon>
                </template>
                <span>{{ $store.getters.translate("is_request") }}</span>
              </v-tooltip>
              <v-tooltip v-else top>
                <template v-slot:activator="{ on, attrs }">
                  {{ note.agreed_with_terms_on }}
                  <v-icon v-bind="attrs" v-on="on" class="mr-3" small color="green">mdi-checkbox-blank-circle</v-icon>
                </template>
                <span>{{ $store.getters.translate("request_fulfilled") }}</span>
              </v-tooltip>
            </template>
          </v-toolbar>
          <v-card-text class="pt-0">
            <v-alert v-if="loaded_note === note.id" dense outlined color="success">
              <v-icon small left color="green">mdi-check-circle</v-icon>
              <small>{{ $store.getters.translate("your_documents_have_been_received") }}</small>
            </v-alert>
            <div class="mb-3" v-if="note.content" v-html="note.content"></div>
            <v-simple-table>
              <template v-slot:default>
                <tbody class="">
                  <tr v-for="(file, index) in note.files" :key="index">
                    <td>
                      <a v-if="isDigital(note)" @click="preview(file)">{{ file.name }}</a>
                      <a v-else @click="download(file)">{{ file.name }}</a>
                      <br />
                      <small>{{ moment(file.created_at).format("DD-MM-YYYY") }}</small>
                    </td>
                    <td v-if="isDigital(note) && note.is_request && !note.request_fulfilled" class="text-end">
                      <v-btn @click="showVerificationDialog(note)" block small text>
                        {{ $store.getters.translate("agreed_to_terms") }}
                      </v-btn>
                    </td>
                    <td class="text-end">
                      <v-icon @click="preview(file)">mdi-eye</v-icon>
                      <v-icon class="ml-3" @click="download(file)">mdi-download</v-icon>
                      <v-icon class="ml-3" v-if="user_id === file.created_by && note.is_request && !note.request_fulfilled" @click="deleteFile(file)">mdi-trash-can</v-icon>
                    </td>
                  </tr>
                </tbody>
                <tfoot v-if="!isDigital(note) && isEditable(note)">
                  <tr>
                    <td colspan="3" class="text-center">
                      <v-file-input
                        :label="$store.getters.translate('add_requested_documents')"
                        class="mt-5"
                        show-size
                        multiple
                        block
                        outlined
                        append-outer-icon="mdi-content-save"
                        prepend-icon="mdi-file"
                        v-model="note.new_files"
                        @click:append-outer="saveNote(note)"
                        @change="saveNote(note)"/>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <portal-document-edit modal_name="document_edit" ref="document_edit"/>
    <!--PHONE NUMBER DIALOG-->
    <v-dialog v-model="show_phone_number_dialog" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar dark color="primary" dense flat>
          <v-btn icon @click="show_phone_number_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.translate('fa_text') }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <v-card-text class="pt-5">
          <v-row>
            <v-col>
              {{ $store.getters.translate('verify_phone_number') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field :label="$store.getters.translate('mobilenumber')" v-model="user_phone_number" placeholder="31612345678"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="verifyNumber" :loading="loading" large block tile color="primary">{{ $store.getters.translate("send") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--VERIFY CODE DIALOG-->
    <v-dialog v-model="show_verify_dialog" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar dark color="primary" dense flat>
          <v-btn icon @click="show_verify_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.translate('fa_text') }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <v-card-text class="pt-5">
          <v-row>
            <v-col>
              {{ $store.getters.translate('verification_sms_sent') }} {{ user_phone_number }}. {{ $store.getters.translate('verify_code') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field :label="$store.getters.translate('verification_code')" v-model="verification_code"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="verifyCode" :loading="loading" large block tile color="primary">
                {{ $store.getters.translate("send") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import PortalDocumentEdit from "./PortalDocumentEdit";

export default {
  name: "BaseNotes",
  props: ["notes", "user_id", "user_type", "user_phone_number", "flat"],
  components: {
    PortalDocumentEdit,
  },
  data() {
    return {
      loading: false,
      loaded_note: null,
      selected_note_id: null,
      show_phone_number_dialog: false,
      show_verify_dialog: false,
      verification_code: null
    };
  },
  methods: {
    saveNote(note) {
      if (note.new_files) {
        var certificatieovereenkomst = false;
        note.new_files.forEach((file) => {
          if(file.name.includes('Certificatieovereenkomst') || file.name.includes('Certificatie-overeenkomst') || file.name.includes('Certificatie overeenkomst')) {
            certificatieovereenkomst = true;
          }
        });
        if(certificatieovereenkomst && !note.name.includes("Certificatieovereenkomst") && !note.name.includes("Certificatie-overeenkomst") && !note.name.includes("Certificatie overeenkomst")) {
          this.$toasted.error("Please upload certification agreement in correct note");
        }
        else {
          const formData = new FormData();
          note.new_files.forEach((file) => {
            formData.append("attachment[]", file);
          });
          formData.append("note_id", note.id);
          this.loading = true;
          this.$http
              .post(this.$store.getters.appUrl + "v2/portal/upload", formData, {
                headers: {"Content-Type": "multipart/form-data"},
              })
              .then(() => {
                this.loaded_note = note.id;
                this.$emit("change");
                this.loading = false;
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                  this.loading = false;
                }
              });
        }
      }
    },
    deleteFile(file) {
      this.loading = true;
      this.$http
        .delete(this.$store.getters.appUrl + "v2/portal/file/" + file.id)
        .then(() => {
          this.$toasted.success(this.$store.getters.translate("deleted"));
          this.$emit("change");
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
            this.loading = false;
          }
        });
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
    download(file) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/portal/file/" + file.id, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            if (error.response.status == 404) {
              this.$toasted.error(this.$store.getters.translate("document_is_not_available"));
            } else {
              this.$toasted.error(error);
            }
          }
          this.loading = false;
        });
    },
    showVerificationDialog(note) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.selected_note_id = note.id;
          this.show_phone_number_dialog = true;
        }
      });
    },
    verifyNumber() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/portal/send-sms", { user_phone_number: this.user_phone_number })
          .then((response) => {
            this.loading = false;
            if(response.data === 'success') {
              this.show_phone_number_dialog = false;
              this.show_verify_dialog = true;
            }
            else {
              this.$toasted.error(response.data.message);
            }
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message);
            this.loading = false;
          });
    },
    verifyCode() {
      if(this.verification_code) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/portal/agree", {
              note_id: this.selected_note_id,
              user_phone_number: this.user_phone_number,
              verification_code: this.verification_code
            })
            .then((response) => {
              this.loading = false;
              if(response.data === 'success') {
                this.show_verify_dialog = false;
                this.loaded_note = this.selected_note_id;
                this.$emit("change");
              }
              else {
                this.$toasted.error(response.data.message);
              }
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error.response.data.message);
                this.loading = false;
              }
            });
      }
    },
    isDigital(note) {
      return (note.tags.includes("Certificatieovereenkomst Digital") || note.tags.includes("Akkoord Inschrijving examen") || note.tags.includes("Opleidingsachtergrond"));
    },
    isEditable(note) {
      if (this.user_type === "assessor") {
        return note.editable_assessor;
      }
      if (this.user_type === "candidate") {
        return note.editable_candidate;
      }
      if (this.user_type === "contact") {
        return note.editable_contact;
      }
    },
  },
  computed: {
    getRecords() {
      return this.notes;
    },
  },
};
</script>